<template>
    <div style="background:#f8f8f8;">
		<el-row style="height: 116px;width: 100%;background: white;">
			<el-col>
				<h3 style="font-size: 30px; text-align: center;line-height: 116px;">官方活动</h3>
			</el-col>
		</el-row>
		<el-row style="margin-top: 15px;">
			<el-col>
				<div style="width: 70%; margin: 0 auto; display: flex; flex-wrap: wrap;">
					<div class="box" @click="godetails">
						<p><img src="../register/img/bg3.jpg" alt="" style="width: 100%;height: 253px;"></p>
						<div style="padding:10px 10px 10px 20px;background: white;">
							<p style="line-height: 20px;">BIMFILM</p>
							<P style="font-size: 14px; margin-top: 5px;"><span>进行中</span>活动截止时间<span >2021-03-20</span></P>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>

export default {
    data() {
        return {};
    },
    components: {},
    //函数方法
    methods: {
		godetails(){
			this.$router.push('OfficialActivities_details')
		}
	},
    mounted() {},
};
</script>
<style scoped>
@import "../style/common.css";
.box{
	width: 32.5%;
	 box-shadow: 0px 0px 6px #c4c4c4;
	 margin-left: 10px;
	 margin-bottom: 10px;
}
</style>


